import React, { createContext } from 'react'
import useSignalrPublic from 'src/signalr/useSignalrPublic'

export const SignalRPublicContext = createContext()

export const SignalRPublicProvider = ({ children }) => {
  const {
    gameRoomInfo,
    gameCreated,
    gameStart,
    gameOpenBet,
    gameCloseBet,
    gameEnd,
    gameCancelled,
    gameOdds,
    declare,
    payOut,
    multiplier,
  } = useSignalrPublic()

  return (
    <SignalRPublicContext.Provider
      value={{
        gameRoomInfo,
        gameCreated,
        gameStart,
        gameOpenBet,
        gameCloseBet,
        gameEnd,
        gameCancelled,
        gameOdds,
        declare,
        payOut,
        multiplier,
      }}
    >
      {children}
    </SignalRPublicContext.Provider>
  )
}
