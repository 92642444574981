import { useState, useEffect } from 'react'
import { LogLevel } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import { useAuth } from 'src/hooks/auth/useAuth'

const useSignalrPublic = () => {
  const [gameCreated, setGameCreated] = useState()
  const [gameStart, setGameStart] = useState()
  const [gameOpenBet, setGameOpenBet] = useState()
  const [gameCloseBet, setGameCloseBet] = useState()
  const [gameEnd, setGameEnd] = useState()
  const [gameCancelled, setGameCancelled] = useState()
  const [multiplier, setMultiplier] = useState()
  const [gameOdds, setGameOdds] = useState({})
  const [declare, setDeclare] = useState()
  const [payOut, setPayOut] = useState({})
  const [gameRoomInfo, setGameRoomInfo] = useState({})
  const [connectionState, setConnectionState] = useState('Disconnected')
  const { token } = useAuth()

  const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNAL_R_PUBLIC_URL, {
      accessTokenFactory: () => token?.accessToken,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  const handleSignalRResponse = (message) => {
    const [messageType, data] = message.split('|')
    if (messageType === 'GameRoomCreated' || messageType === 'GameRoomEnd') {
      const publicData = JSON.parse(data)
      setGameRoomInfo(publicData)
    }
    if (messageType === 'GameCreated') {
      const publicData = JSON.parse(data)
      setGameCreated(publicData)
    }
    if (messageType === 'GameOpenBet') {
      const publicData = JSON.parse(data)
      setGameOpenBet(publicData)
    }
    if (messageType === 'GameMultiplier') {
      const publicData = JSON.parse(data)
      setMultiplier(publicData)
    }
    if (messageType === 'GameClosedBet') {
      const publicData = JSON.parse(data)
      setGameCloseBet(publicData)
    }
    if (messageType === 'GameStart') {
      const publicData = JSON.parse(data)
      setGameStart(publicData)
    }
    if (messageType === 'GameEnd') {
      const publicData = JSON.parse(data)
      setGameEnd(publicData)
    }
    if (messageType === 'GameCancelled') {
      const publicData = JSON.parse(data)
      setGameCancelled(publicData)
    }
    if (messageType === 'GameDeclareWinner') {
      const publicData = JSON.parse(data)
      setDeclare(publicData)
    }
    if (messageType === 'GAME_COMPLETED_PAYOUT') {
      const publicData = JSON.parse(data)
      setPayOut(publicData)
    }
    if (messageType === 'ODDS') {
      const publicData = JSON.parse(data)
      setGameOdds(publicData)
    }
  }

  const connectToSignalR = () => {
    newConnection
      .start()
      .then(() => {
        setConnectionState(newConnection.state)
        console.log('Connected public!')
        newConnection.send('RegisterToFeed', process.env.REACT_APP_GAMETYPE)
      })
      .catch((e) => console.log('Connection failed: ', e))
  }

  const disconnectToSignalR = () => {
    newConnection
      .stop()
      .then(() => {
        setConnectionState(newConnection.state)
        console.log('Disconnected public!')
      })
      .catch((e) => console.log('Disconnection failed: ', e))
  }

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && connectionState === 'Disconnected') {
      connectToSignalR()
    } else {
      disconnectToSignalR()
    }
  }

  newConnection.on('ReceivePublicUpdate', (message) => {
    handleSignalRResponse(message)
  })

  newConnection.onclose(() => {
    setConnectionState(newConnection.state)
  })

  useEffect(() => {
    if (newConnection.state === 'Disconnected') {
      connectToSignalR()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  return {
    gameRoomInfo,
    gameCreated,
    gameStart,
    gameOpenBet,
    gameCloseBet,
    gameEnd,
    gameCancelled,
    gameOdds,
    declare,
    payOut,
    multiplier,
  }
}

export default useSignalrPublic
